import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import Card from "components/card";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "ssd"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcparts/ssd_size.PNG"}) { ...eyecatchImg },
    realarea: file(relativePath: { eq: "storage/realarea.PNG"}) { ...normalImg },
    storageguide: file(relativePath: { eq: "storage/storage-guide.png"}) { ...normalImg },
    ssd_youryou: file(relativePath: { eq: "storage/ssd_youryou.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`【256GB,512GB,1TB】SSDの容量のおすすめは何か？`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="SSDのおすすめの容量は？" mdxType="Image" />
    <p>{`ノートパソコンを購入する際、SSDの容量選択で悩むことは多い。`}<em parentName="p">{`128GB`}</em>{`、`}<em parentName="p">{`256GB`}</em>{`、`}<em parentName="p">{`512GB`}</em>{`と2倍、2倍になっていき、何故もう少し細かいサイズの区切りはないのかと苛立ちながらも割り切っていくしかない。`}</p>
    <p>{`そこで、本ページでは最初に実際に自由に使用できる容量、および動画・映像・ゲームなどにどれぐらいの容量を使うのかを解説した上で、SSDの容量のおすすめをケース別に考えていくことにする。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "実際に使用できるSSDの容量は意外と少ない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%AE%9F%E9%9A%9B%E3%81%AB%E4%BD%BF%E7%94%A8%E3%81%A7%E3%81%8D%E3%82%8BSSD%E3%81%AE%E5%AE%B9%E9%87%8F%E3%81%AF%E6%84%8F%E5%A4%96%E3%81%A8%E5%B0%91%E3%81%AA%E3%81%84",
        "aria-label": "実際に使用できるSSDの容量は意外と少ない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`実際に使用できるSSDの容量は意外と少ない`}</h2>
    <p>{`パソコンのスペック表で表示されているSSDの容量は、Windowsで実際に自由に使える容量ではない。`}</p>
    <p>{`Windowsの1GBの定義がSSDのスペック表記と異なる事やプリインストールされているソフトウェアなどにより、実際に使える容量は少なくなることを説明していく。`}</p>
    <h3 {...{
      "id": "実際にWindowsで使用できる容量は？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%AE%9F%E9%9A%9B%E3%81%ABWindows%E3%81%A7%E4%BD%BF%E7%94%A8%E3%81%A7%E3%81%8D%E3%82%8B%E5%AE%B9%E9%87%8F%E3%81%AF%EF%BC%9F",
        "aria-label": "実際にWindowsで使用できる容量は？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`実際にWindowsで使用できる容量は？`}</h3>
    <p>{`SSDメーカーがスペック表に記載する容量と、WindowsPCの容量ではGBの測り型が異なる。`}</p>
    <p>{`SSDメーカーは1GBを`}<em parentName="p">{`1GB = 1000MB = 10000000KB = 1000000000B`}</em>{`と計算するが、Windowsは２進数計算で`}<em parentName="p">{`1GB=1024MB=1048576KB=1073741824B`}</em>{`と1024をかけて計算する。`}</p>
    <p>{`Windowsが計算する1GBの定義はストレージメーカーの1.0737GBということになる。メーカーが嘘をついているわけではないが、Windowsを使うならばまずはこの変換をかけてやる必要がある。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <thead>
    <tr>
      <th>メーカーの容量</th>
      <th>Windowsでの容量</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1GB</td>
      <td>1.0737GB</td>
    </tr>
    <tr>
      <td>128GB</td>
      <td>119GB</td>
    </tr>
    <tr>
      <td>256GB</td>
      <td>238GB</td>
    </tr>
    <tr>
      <td>512GB</td>
      <td>476GB</td>
    </tr>
    <tr>
      <td>1TB</td>
      <td>931GB</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`ストレージ製品によって誤差は多少あるかもしれないが、これが`}<strong parentName="p">{`Windowsで実際にストレージとして認識される容量`}</strong>{`である。`}</p>
    <h3 {...{
      "id": "OSとアプリでマイナス45GBして考える",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#OS%E3%81%A8%E3%82%A2%E3%83%97%E3%83%AA%E3%81%A7%E3%83%9E%E3%82%A4%E3%83%8A%E3%82%B945GB%E3%81%97%E3%81%A6%E8%80%83%E3%81%88%E3%82%8B",
        "aria-label": "OSとアプリでマイナス45GBして考える permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OSとアプリでマイナス45GBして考える`}</h3>
    <Image {...props} name="realarea" alt="実際に使用可能な領域" mdxType="Image" />
    <p>{`Windowsのパソコンを購入したとしよう。まずOSの占有領域が`}<em parentName="p">{`15GB`}</em>{`程度あり、WordやExcelやブラウザなどのアプリケーションで別途`}<em parentName="p">{`10GB`}</em>{`程度は使用されている。よって`}<em parentName="p">{`25GBは自由に使用する事ができない`}</em>{`。`}</p>
    <p>{`さらにSSDは残り容量が少なくなるほど、空き容量を探すことに時間を取られて速度が低下し、寿命を早めてしまうため、`}<em parentName="p">{`20GB程度は余裕を持って残しておきたい`}</em>{`。`}</p>
    <p>{`こう考えると`}<em parentName="p">{`45GB程度の領域は取られてしまう`}</em>{`ため、`}<strong parentName="p">{`128GBのノートパソコンで実際に使用できる容量は80GB程度`}</strong>{`ということになる。`}</p>
    <p>{`Windowsの1GBの定義を考えると更に少なく、各容量から45GBを引くと次のようになる。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <thead>
    <tr>
      <th>メーカーの容量</th>
      <th>Windowsの容量</th>
      <th>実際使える容量</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>128GB</td>
      <td>119GB</td>
      <td>74GB</td>
    </tr>
    <tr>
      <td>256GB</td>
      <td>238GB</td>
      <td>193GB</td>
    </tr>
    <tr>
      <td>512GB</td>
      <td>476GB</td>
      <td>431GB</td>
    </tr>
    <tr>
      <td>1TB</td>
      <td>931GB</td>
      <td>886GB</td>
    </tr>
  </tbody>
    </BasicTable>
    <h3 {...{
      "id": "使い続けるとファイルのゴミやキャッシュが溜まる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BD%BF%E3%81%84%E7%B6%9A%E3%81%91%E3%82%8B%E3%81%A8%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E3%82%B4%E3%83%9F%E3%82%84%E3%82%AD%E3%83%A3%E3%83%83%E3%82%B7%E3%83%A5%E3%81%8C%E6%BA%9C%E3%81%BE%E3%82%8B",
        "aria-label": "使い続けるとファイルのゴミやキャッシュが溜まる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`使い続けるとファイルのゴミやキャッシュが溜まる`}</h3>
    <p>{`スマートフォンを使っていて、知らぬ間に容量がいっぱいになっていた、という経験をしたことはないだろうか？`}</p>
    <p>{`これは自分でダウンロードしたファイルを削除していなかったり、ブラウザのキャッシュが溜まっているためである。`}</p>
    <p>{`ブラウザなどのアプリケーションは、`}<strong parentName="p">{`２度目に見る情報を素早く表示させるために、初回のアクセス時にファイルをこっそりとローカルに保存(=キャッシュ)`}</strong>{`している。`}</p>
    <p>{`このため、多くの情報が知らぬ間に溜まり容量不足に陥るのである。あるいはWindowsのアップデートの度にバックアップ用のファイルが蓄積されたりもして容量を圧迫する。`}</p>
    <p>{`これらを合わせると少なくとも`}<em parentName="p">{`毎年5GB程度容量を使う`}</em>{`ことになる。コマメに削除もできるが面倒でスキルも要するので、できればゴミを貯めたまま寿命を迎えたい。`}</p>
    <p>{`ノートパソコンを５年使うと考え、毎年5GBゴミがたまるとすると、使用できる容量は最終的に下記のようになる。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <thead>
    <tr>
      <th>メーカーの容量</th>
      <th>5年使用で自由に使える容量</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>128GB</td>
      <td>49GB</td>
    </tr>
    <tr>
      <td>256GB</td>
      <td>168GB</td>
    </tr>
    <tr>
      <td>512GB</td>
      <td>406GB</td>
    </tr>
    <tr>
      <td>1TB</td>
      <td>906GB</td>
    </tr>
  </tbody>
    </BasicTable>
    <p><em parentName="p">{`128GBを購入して失敗する人が後を絶たない`}</em>{`理由はもちろんおわかりですね？`}</p>
    <p>{`100GBは自由に使えるだろうと高を括っていいたら、実際にはその半分にも満たなかったからである。`}</p>
    <p>{`128GBと比べて256GBのパソコンが実際に使える容量は4倍近くに増えるのである。`}</p>
    <h2 {...{
      "id": "動画・写真・音楽・ゲーム等で使われる容量はどれぐらい？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%8B%95%E7%94%BB%E3%83%BB%E5%86%99%E7%9C%9F%E3%83%BB%E9%9F%B3%E6%A5%BD%E3%83%BB%E3%82%B2%E3%83%BC%E3%83%A0%E7%AD%89%E3%81%A7%E4%BD%BF%E3%82%8F%E3%82%8C%E3%82%8B%E5%AE%B9%E9%87%8F%E3%81%AF%E3%81%A9%E3%82%8C%E3%81%90%E3%82%89%E3%81%84%EF%BC%9F",
        "aria-label": "動画・写真・音楽・ゲーム等で使われる容量はどれぐらい？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`動画・写真・音楽・ゲーム等で使われる容量はどれぐらい？`}</h2>
    <div className="b-m-c">
  <Image {...props} name="storageguide" alt="SSD容量の目安" mdxType="Image" />
    </div>
    <p>{`次に、容量の簡単な目安としては図のようになる。一般的な`}<em parentName="p">{`FullHDのビデオならば８時間`}</em>{`、`}<em parentName="p">{`音楽ならば2万曲`}</em>{`、`}<em parentName="p">{`写真が２万枚`}</em>{`といったところ。`}</p>
    <p>{`ゲームは容量が大きいものから小さいものまで様々であるが、フォートナイトなど`}<em parentName="p">{`大型のゲームは100GB近く容量を消費してしまう`}</em>{`こともある。`}</p>
    <p>{`また、高画質な4K動画を保存するような場合には、わずか２時間足らずで100GBを超えてしまう。その他、Adobeなどのアプリはそれぞれ1GB程度が普通である。`}</p>
    <p>{`この情報を元に、どれぐらいの容量が必要かをおすすめしていく。`}</p>
    <h2 {...{
      "id": "おすすめのSSDの容量はどれぐらいか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AESSD%E3%81%AE%E5%AE%B9%E9%87%8F%E3%81%AF%E3%81%A9%E3%82%8C%E3%81%90%E3%82%89%E3%81%84%E3%81%8B%EF%BC%9F",
        "aria-label": "おすすめのSSDの容量はどれぐらいか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`おすすめのSSDの容量はどれぐらいか？`}</h2>
    <p>{`ある程度感覚が分かったと思うので、128GB、256GB、512GB、1TBの各容量がどのようなケースにおすすめなのかを記述していく。ざっくりとは下記の図のようになる。`}</p>
    <Image {...props} name="ssd_youryou" alt="SSD容量の目安" mdxType="Image" />
    <p>{`この理由と、外付けHDDに保存するなどの対処法等含めて解説していく。`}</p>
    <h3 {...{
      "id": "128GBのSSDはメディアを一切保存しない人",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#128GB%E3%81%AESSD%E3%81%AF%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E3%82%92%E4%B8%80%E5%88%87%E4%BF%9D%E5%AD%98%E3%81%97%E3%81%AA%E3%81%84%E4%BA%BA",
        "aria-label": "128GBのSSDはメディアを一切保存しない人 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`128GBのSSDはメディアを一切保存しない人`}</h3>
    <p>{`毎年増えるキャッシュやゴミファイルと、OSや初期アプリの容量を考えると、利用できる容量は50GB足らずになってしまう。`}</p>
    <p>{`動画や写真を気にせずパソコンへ入れていくとすぐに容量が一杯になることは目に見えており、またアプリケーションを使っているだけでも何年も使っていると際どいラインまでいってしまう。`}</p>
    <p>{`このため`}<em parentName="p">{`一般的にあまりおすすめはできない`}</em>{`が、たとえば`}<strong parentName="p">{`インターネットを主に楽しみたい人や軽い作業に限定したオフィスワーク専用とする人`}</strong>{`ならば128GBであっても問題はない。`}</p>
    <p>{`初めからパソコンの使用目的が明確であり、それが容量をほとんど消費しないことが分かっている場合に限り128GBは選択肢となる。`}</p>
    <h3 {...{
      "id": "256GBは広くおすすめできる容量",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#256GB%E3%81%AF%E5%BA%83%E3%81%8F%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%A7%E3%81%8D%E3%82%8B%E5%AE%B9%E9%87%8F",
        "aria-label": "256GBは広くおすすめできる容量 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`256GBは広くおすすめできる容量`}</h3>
    <p>{`256GBは512GBと並び定番の容量である。基本的にアプリケーションをどれだけインストールしても、`}<strong parentName="p">{`動画や写真をある程度保存しても容量にはそれなりの余裕がある`}</strong>{`。`}</p>
    <p>{`オフィスワークをする人や、軽い動画や写真の編集を行いたい人など幅広い層にとっても十分な容量といえる。`}</p>
    <h3 {...{
      "id": "512GBはゲームや動画編集をよく行う人、余裕が欲しい人",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#512GB%E3%81%AF%E3%82%B2%E3%83%BC%E3%83%A0%E3%82%84%E5%8B%95%E7%94%BB%E7%B7%A8%E9%9B%86%E3%82%92%E3%82%88%E3%81%8F%E8%A1%8C%E3%81%86%E4%BA%BA%E3%80%81%E4%BD%99%E8%A3%95%E3%81%8C%E6%AC%B2%E3%81%97%E3%81%84%E4%BA%BA",
        "aria-label": "512GBはゲームや動画編集をよく行う人、余裕が欲しい人 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`512GBはゲームや動画編集をよく行う人、余裕が欲しい人`}</h3>
    <p>{`ゲームは１本で100GBに到達する事もあるので、256GBでは全ての容量をゲームのために使用しても２本～３程度しか入らず、小まめにゲームのインストールとアンインストールを繰り返す羽目になる。`}</p>
    <p>{`このため`}<strong parentName="p">{`3Dゲームをインストールして遊びたいならば、`}<em parentName="strong">{`512GBが最低ライン`}</em></strong>{`となる。`}</p>
    <p>{`また動画編集を頻繁に行う人も素材が多くなってくると256GBでは足りなくなる可能性も高いため512GBあれば安心であろう。`}</p>
    <p>{`その他、保存している動画像が多い人、容量を気にせず使いたい人で予算がある人などは512GBはおすすめできる。`}</p>
    <h3 {...{
      "id": "1TBは動画編集もゲームも行う人、コアゲーマー等",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1TB%E3%81%AF%E5%8B%95%E7%94%BB%E7%B7%A8%E9%9B%86%E3%82%82%E3%82%B2%E3%83%BC%E3%83%A0%E3%82%82%E8%A1%8C%E3%81%86%E4%BA%BA%E3%80%81%E3%82%B3%E3%82%A2%E3%82%B2%E3%83%BC%E3%83%9E%E3%83%BC%E7%AD%89",
        "aria-label": "1TBは動画編集もゲームも行う人、コアゲーマー等 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1TBは動画編集もゲームも行う人、コアゲーマー等`}</h3>
    <p>{`パソコンをフル活用して動画編集もゲームも楽しみたいという人は1TB積んでおいても良いだろう。またゲームは512GBが最低ラインであるため、より多くのゲームをインストールして遊びたいならば1TB積んでおくと良い。`}</p>
    <p>{`その他容量の大きい4K動画編集を行おうとしている人などにもおすすめできる。`}</p>
    <p>{`なお、1TBのSSDは値段が高いため、`}<strong parentName="p">{`保存する動画の量が大きい場合には512GBで抑えて外付けのHDDで代用する`}</strong>{`のも現実的な選択肢となる。`}</p>
    <h2 {...{
      "id": "256GBか512GBはどちらがおすすめか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#256GB%E3%81%8B512GB%E3%81%AF%E3%81%A9%E3%81%A1%E3%82%89%E3%81%8C%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%8B%EF%BC%9F",
        "aria-label": "256GBか512GBはどちらがおすすめか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`256GBか512GBはどちらがおすすめか？`}</h2>
    <p>{`SSDで一番迷う選択は256GBと512GBであると思う。ゲームや動画編集をよくおこなう人ならば512GBを選んでおけば良いが、動画や写真を多く保存したい人はどうか？`}</p>
    <p>{`ゲームや動画編集では、ゲームデータのロードや、動画像ファイルの読み込み時間が快適性に大きな影響を与えるために、高速なSSDにデータを置いておくことが重要となる。`}</p>
    <p>{`しかし、撮りためた動画や写真を視聴することにおいて、SSDとHDDの体験的な違いはほとんどない。`}</p>
    <p>{`そう考えると、`}<strong parentName="p">{`動画像の保存は外付けのHDDやUSBメモリに任せ256GBを選択することは理にかなっている`}</strong>{`といえる。外付けならば容量を気にすることなく追加で容量を購入できる。`}</p>
    <p>{`しかし、外付けは常時取り付けているものではないし、必要な時に取り出せない可能性がある。15.6インチなどのスタンダードノートだとSSDとHDDを組み合わせるオプションが大抵選べるため、SSD:256GB、HDD:1TBなどのモデルを選べば良い。`}</p>
    <p>{`モバイルノートの場合は、データをできる限り持ち歩きたい、`}<strong parentName="p">{`外付けなんて面倒なことはしたくないという人は512GBを選べば良い`}</strong>{`だろう。`}</p>
    <p>{`あるいは、月額料金はかかるが、Google/Microsoftなどとクラウドストレージの契約をしてそこにデータをぶち込んでおくのも選択肢である。動画像やその他ファイルの保存をどうすべきか、対策を表にまとめたので参考にして欲しい。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>256GBで容量不足時の対策</th>
      <th>特徴</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>外付けHDDやUSBメモリを別途購入</td>
      <td>必要量に合わせて購入できる。移す作業はコピーするだけだが面倒、外付けHDDも物理的に邪魔である。ほとんど見ない動画・写真には良いのではないか。</td>
    </tr>
    <tr>
      <td>1TBのHDDを付属させる</td>
      <td>モバイルノートでは付属するオプションが無いのが問題。スタンダードノートでの主な選択肢が256GB SSD + 1TB HDD。HDDは安価で動画像保存にはデメリットも少ないのでおすすめの選択。</td>
    </tr>
    <tr>
      <td>購入時512GBを選択</td>
      <td>最も手軽、256GBでは足りないだろうと購入時に予想されるなら選んでおくべきだが、価格と相談も必要</td>
    </tr>
    <tr>
      <td>クラウドストレージを活用</td>
      <td>100GBで年間2500円ぐらい、1TBで10000円程度かかる。安いプランを見つけてもいずれは有料化される可能性あり。</td>
    </tr>
  </tbody>
    </BasicTable>
    <br />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      